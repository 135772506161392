import {combineReducers} from 'redux';
import * as constants from '../constants';

export function generalError(state = null, action) {
    switch (action.type){
        case constants.GENERAL_ERROR:
            return null;
        default:
            break;
    }
    return state;
}

const userLogin = (state = null, action) =>
  singleSwitchReducer(state, action, constants.USER_LOGIN);

const userEmailVerification = (state = null, action) =>
  singleSwitchReducer(state, action, constants.USER_EMAIL_VERIFICATION);

const loginRequired = (state = null, action) =>
  singleSwitchReducer(state, action, constants.LOGIN_REQUIRED);

const userRegister = (state = null, action) =>
  singleSwitchReducer(state, action, constants.USER_REGISTER);

const getUserConsents = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_USER_CONSENTS);

const updateUserConsents = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_USER_CONSENTS);

const getParties = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PARTIES);

const getParty = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PARTY);

const getPartySummaryStatus = (state = null, action) =>
   singleSwitchReducer(state, action, constants.GET_PARTY_SUMMARY_STATUS);

export function preparePartyReport(state = null, action) {
  return singleSwitchReducer(state, action, constants.PREPARE_PARTY_REPORT);
}

export function getPartyAttachments(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PARTY_ATTACHMENTS);
}

export function getPartyAttachmentsOneDrive(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PARTY_ATTACHMENTS_ONE_DRIVE);
}

export function createParty(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_PARTY);
}
export function uploadPartyCsv(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPLOAD_PARTY_CSV);
}

export function getPartyRisk(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PARTY_RISK);
}

export function updateParty(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_PARTY);
}

export function updatePartyStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_PARTY_STATUS);
}

export function setPartyRiskStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.SET_PARTY_RISK_STATUS);
}

export function deleteParty(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_PARTY);
}

export function createHistoryEvent(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_HISTORY_EVENT);
}

export function updateHistoryEvent(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_HISTORY_EVENT);
}

export function getHistoryEvents(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_HISTORY_EVENTS);
}

export function getHistoryEvent(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_HISTORY_EVENT);
}

export function deleteHistoryEvent(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_HISTORY_EVENT);
}

export function getEventComments(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_EVENT_COMMENTS);
}

export function createComment(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_COMMENT);
}

export function updateComment(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_COMMENT);
}

export function deleteComment(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_COMMENT);
}

export function getUser(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_USER);
}

export function userChangePassword(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_CHANGE_PASSWORD);
}

export function deleteUser(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_USER);
}

export function getUserToDelete(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_USER_TO_DELETE);
}

export function sendDeleteUserEmail(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_SEND_DELETE_USER_EMAIL);
}

export function userResendVerificationEmail(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_RESEND_VERIFICATION_EMAIL);
}

export function userSendResetPasswordEmail(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_SEND_RESET_PASSWORD_EMAIL);
}

export function resetUserPassword(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_RESET_PASSWORD);
}

export function addBeneficiary(state = null, action) {
  return singleSwitchReducer(state, action, constants.ADD_BENEFICIARY);
}

export function deleteBeneficiary(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_BENEFICIARY);
}

export function getBeneficiaries(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_BENEFICIARIES);
}

export function updateBeneficiary(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_BENEFICIARY);
}

export function getBoardMembers(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_BOARDMEMBERS);
}

export function addBoardMember(state = null, action) {
  return singleSwitchReducer(state, action, constants.ADD_BOARDMEMBER);
}

export function updateBoardMember(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_BOARDMEMBER);
}

export function deleteBoardMember(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_BOARDMEMBER);
}

export function getRegonAutofill(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_REGON_AUTOFILL);
}

export function getBeneficiariesByNIPFromCRBR(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_BENEFICIARIES_BY_NIP_FROM_CRBR);
}

export function checkSubscription(state = null, action) {
  return singleSwitchReducer(state, action, constants.CHECK_SUBSCRIPTION);
}

export function getSubscriptions(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SUBSCRIPTIONS);
}

export function getSubscriptionPlans(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SUBSCRIPTION_PLANS);
}

export function getOrders(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDERS);
}

export function getOrder(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDER);
}

export function getProductsData(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PRODUCTS_DATA);
}

export function getOrderCalculation(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDER_CALCULATION);
}

export function createTransaction(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_TRANSACTION);
}

export function updateTransaction(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_TRANSACTION);
}

export function updateTransactionStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_TRANSACTION_STATUS);
}

export function getTransactions(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_TRANSACTIONS);
}

export function getTransactionSummaryStatus(state = null, action){
 return singleSwitchReducer(state, action, constants.GET_TRANSACTION_SUMMARY_STATUS);
} 

export function getTransaction(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_TRANSACTION);
}

export function prepareTransactionReport(state = null, action) {
  return singleSwitchReducer(state, action, constants.PREPARE_TRANSACTION_REPORT);
}

export function getTransactionAttachments(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_TRANSACTION_ATTACHMENTS);
}

export function getTransactionAttachmentsOneDrive(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_TRANSACTION_ATTACHMENTS_ONE_DRIVE);
}

export function createGoogleAuthToken(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_GOOGLE_AUTH_TOKEN);
}

export function deleteTransaction(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_TRANSACTION);
}

export function getAlerts(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ALERTS);
}

export function getAlert(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ALERT);
}

export function deleteAlert(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_ALERT);
}

export function updateAlertStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_ALERT_STATUS);
}

export function createTask(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_TASK);
}

export function getTasks(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_TASKS);
}

export function getTask(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_TASK);
}

export function updateTask(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_TASK);
}

export function updateTaskStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_TASK_STATUS);
}

export function getTransactionRisk(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_TRANSACTION_RISK);
}

export function setTransactionRiskStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.SET_TRANSACTION_RISK_STATUS);
}

export function deleteTask(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_TASK);
}

export function addPartyAttachment(state = null, action) {
  return singleSwitchReducer(state, action, constants.ADD_PARTY_ATTACHMENT);
}

export function addPartyAttachmentOneDrive(state = null, action) {
  return singleSwitchReducer(state, action, constants.ADD_PARTY_ATTACHMENT_ONE_DRIVE);
}

export function addTransactionAttachment(state = null, action) {
  return singleSwitchReducer(state, action, constants.ADD_TRANSACTION_ATTACHMENT);
}

export function addTransactionAttachmentOneDrive(state = null, action) {
  return singleSwitchReducer(state, action, constants.ADD_TRANSACTION_ATTACHMENT_ONE_DRIVE);
}

export function deleteAttachment(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_ATTACHMENT);
}

export function deleteAttachmentOneDrive(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_ATTACHMENT_ONE_DRIVE);
}

export function updateAttachmentDescription(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_ATTACHMENT_DESCRIPTION);
}

export function updateAttachmentDescriptionOneDrive(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_ATTACHMENT_DESCRIPTION_ONE_DRIVE);
}

export function getGoogleDriveInfo(state = null, action) {
  return singleSwitchReducer(state, action, constants.GOOGLE_INFO);
}

export function googleLogin(state = null, action) {
  return singleSwitchReducer(state, action, constants.GOOGLE_LOGIN);
}

export function googleLogout(state = null, action) {
  return singleSwitchReducer(state, action, constants.GOOGLE_LOGOUT);
}

export function uploadTransactionSignedXml(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPLOAD_TRANSACTION_SIGNED_XML);
}

export function uploadTransactionSignedFormXml(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPLOAD_TRANSACTION_SIGNED_FORM_XML);
}

export function getRiskManagementStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_RISK_MANAGEMENT_STATUS);
}

export function updateRiskManagementStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_RISK_MANAGEMENT_STATUS);
}

export function getAvailableRules(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_AVAILABLE_RULES);
}

export function getRule(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_RULE);
}

export function getRuleLimits(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_RULE_LIMITS);
}

const createNewTeam = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_TEAM);

const deleteTeam = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_TEAM);

const leaveTeam = (state = null, action) =>
  singleSwitchReducer(state, action, constants.LEAVE_TEAM);

const getUserTeams = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_USER_TEAMS);

const getTeamData = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_TEAM_DATA);

const updateTeamData = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_TEAM_DATA);

const getUserRules = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_USER_RULES);

const getTeamMembers = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_TEAM_MEMBERS);

const inviteTeamMember = (state = null, action) =>
  singleSwitchReducer(state, action, constants.INVITE_TEAM_MEMBERS);

const updateTeamMemberRole = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_TEAM_MEMBER_ROLE);

const deleteTeamMember = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_TEAM_MEMBER);

const getTeamInvitationData = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_TEAM_INVITATION_DATA);

const teamMemberRegister = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_TEAM_MEMBER);

const getTeamInvitations = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_TEAM_INVITATIONS);

const deleteTeamInvitation = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_TEAM_INVITATION);

const handleTeamInvitation = (state = null, action) =>
  singleSwitchReducer(state, action, constants.HANDLE_TEAM_INVITATION);

const changeCurrentTeamContext = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CHANGE_TEAM);

const addUserRule = (state = null, action) =>
  singleSwitchReducer(state, action, constants.ADD_USER_RULE);

const removeUserRule = (state = null, action) =>
  singleSwitchReducer(state, action, constants.REMOVE_USER_RULE);

const updateRuleLimits = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_RULE_LIMITS);

const addMissingRuleData = (state = null, action) =>
  singleSwitchReducer(state, action, constants.ADD_MISSING_RULE_DATA);

const getSuggestedRiskStatusRecalculate = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_SUGGESTED_RISK_STATUS_RECALCULATE);

const setRuleResultStatus = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SET_RULE_RESULTS_STATUS);

const rejectRuleResult = (state = null, action) =>
  singleSwitchReducer(state, action, constants.REJECT_RULE_RESULTS);

const createIndividualCustomerProfile = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_INDIVIDUAL_CUSTOMER_PROFILE);

const updateIndividualCustomerProfileList = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_INDIVIDUAL_CUSTOMER_PROFILE);

const getIndividualCustomerProfileList = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_INDIVIDUAL_CUSTOMER_PROFILE);

const deleteIndividualCustomerProfile = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_INDIVIDUAL_CUSTOMER_PROFILE);

export function updateHighRiskPkdList(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_HIGH_RISK_PKD_LIST);
}

export function createHighRiskCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_HIGH_RISK_COUNTRY_LIST);
}

export function updateHighRiskCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_HIGH_RISK_COUNTRY_LIST);
}

export function deleteHighRiskCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_HIGH_RISK_COUNTRY_LIST);
}

export function createCorruptCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_CORRUPT_COUNTRY_LIST);
}

export function updateCorruptCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_CORRUPT_COUNTRY_LIST);
}

export function deleteCorruptCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_CORRUPT_COUNTRY_LIST);
}

export function createTaxHavenCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_TAX_HAVEN_COUNTRY_LIST);
}

export function updateTaxHavenCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_TAX_HAVEN_COUNTRY_LIST);
}

export function deleteTaxHavenCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_TAX_HAVEN_COUNTRY_LIST);
}

export function createSanctionCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_SANCTION_COUNTRY_LIST);
}

export function updateSanctionCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_SANCTION_COUNTRY_LIST);
}

export function deleteSanctionCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_SANCTION_COUNTRY_LIST);
}

export function createTerrorismCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_TERRORISM_COUNTRY_LIST);
}

export function updateTerrorismCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_TERRORISM_COUNTRY_LIST);
}

export function deleteTerrorismCountryList(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_TERRORISM_COUNTRY_LIST);
}

export function createApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_APIKEYS);
}

export function getApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_APIKEYS);
}

export function getAllActiveApiKeys(state = null, action) {
   return singleSwitchReducer(state, action, constants.GET_ALL_ACTIVE_API_KEYS);
}

export function disableApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.DISABLE_APIKEYS);
}

export function getShopProducts(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SHOP_PRODUCTS);
}

export function getShopCart(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SHOP_CART);
}

export function addShopItem(state = null, action) {
  return singleSwitchReducer(state, action, constants.ADD_SHOP_ITEM);
}
export function deleteShopItem(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_SHOP_ITEM);
}
export function updateShopItem(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_SHOP_ITEM);
}

export function addDiscountCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.ADD_DISCOUNT_CODE);
}
export function deleteDiscountCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.DELETE_DISCOUNT_CODE);
}

export function createOrder(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_ORDER);
}

export function cancelOrder(state = null, action) {
  return singleSwitchReducer(state, action, constants.CANCEL_ORDER);
}

export function getSession(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SESSION);
}

const verifyTunstileToken = (state = null, action) =>
  singleSwitchReducer(state, action, constants.VERIFY_TURNSTILE_TOKEN);

const createEmployees = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_EMPLOYEES);

const getEmployees = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_EMPLOYEES);

const getEmployee = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_EMPLOYEE);

const updateEmployees = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_EMPLOYEES);

const deleteEmployee = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_EMPLOYEE);

const addEmployeeTraining = (state = null, action) =>
  singleSwitchReducer(state, action, constants.ADD_EMPLOYEE_TRAINING);

const getEmployeeTrainings = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_EMPLOYEE_TRAININGS);

const deleteEmployeeTraining = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_EMPLOYEE_TRAINING);

const surveyCheck = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SURVEY_CHECK);

const surveySendEmail = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SURVEY_SEND_EMAIL);

const sanctionsListsSearch = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SANCTIONS_LISTS_SEARCH);

const sanctionsListsSearchNoForm = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SANCTIONS_LISTS_SEARCH_NO_FORM);

const sanctionsListsTransactionSearchNoForm = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SANCTIONS_LISTS_SEARCH_TRANSACTION_NO_FORM);

const partySanctionsListsScreen = (state = null, action) =>
  singleSwitchReducer(state, action, constants.PARTY_SANCTIONS_LISTS_SCREEN)

  const transactionSanctionsListsScreen = (state = null, action) =>
  singleSwitchReducer(state, action, constants.TRANSACTION_SANCTIONS_LISTS_SCREEN)

const userInvoiceData = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_USER_INVOICE_DATA);

const updatedUserInvoiceData = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_USER_INVOICE_DATA);

const getFinancialSecurityMeasuresExecutions = (state = null, action) =>
  singleSwitchReducer(state, action, constants.FINANCIAL_SECURITY_MEASURES_EXECUTIONS_GET);

const findFinancialSecurityMeasuresExecution = (state = null, action) =>
  singleSwitchReducer(state, action, constants.FINANCIAL_SECURITY_MEASURES_EXECUTION_FIND);

const createFinancialSecurityMeasuresExecution = (state = null, action) =>
  singleSwitchReducer(state, action, constants.FINANCIAL_SECURITY_MEASURES_EXECUTION_CREATE);

const deleteFinancialSecurityMeasureExecution = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_FINANCIAL_SECURITY_MEASURES_EXECUTION);

const uploadFinancialSecurityMeasureAttachment = (state = null, action) =>
  singleSwitchReducer(state, action, constants.FINANCIAL_SECURITY_MEASURES_UPLOAD_ATTACHMENT);

const uploadFinancialSecurityMeasureAttachmentOneDrive = (state = null, action) =>
  singleSwitchReducer(state, action, constants.FINANCIAL_SECURITY_MEASURES_UPLOAD_ATTACHMENT_ONE_DRIVE);

const getGoogleAccount = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GOOGLE_ACCOUNT_GET);

const getGoogleAccountConnectionRedirectUrl = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GOOGLE_ACCOUNT_CONNECTION_REDIRECT_URL_GET );

const createGoogleAccountConnection = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GOOGLE_ACCOUNT_CONNECTION_CREATE);

const disconnectGoogleAccount = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GOOGLE_ACCOUNT_DISCONNECT);

const getMicrosoftAccount = (state = null, action) =>
    singleSwitchReducer(state, action, constants.MICROSOFT_ACCOUNT_GET);

const getMicrosoftAccountConnectionRedirectUrl = (state = null, action) =>
  singleSwitchReducer(state, action, constants.MICROSOFT_ACCOUNT_CONNECTION_REDIRECT_URL_GET );

const createMicrosoftAccountConnection = (state = null, action) =>
  singleSwitchReducer(state, action, constants.MICROSOFT_ACCOUNT_CONNECTION_CREATE);

const disconnectMicrosoftAccount = (state = null, action) =>
  singleSwitchReducer(state, action, constants.MICROSOFT_ACCOUNT_DISCONNECT);

const logSwitch = (state = null, action) =>
  singleSwitchReducer(state, action, constants.LOG_SWITCH);

const createApplicant = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_APPLICANT);

const getApplicant = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_APPLICANT);

const getApplicants = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_APPLICANTS);

const submitApplicant = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SUBMIT_APPLICANT);

const testCallbackRequestResult = (state = null, action) =>
  singleSwitchReducer(state, action, constants.MAKE_TEST_CALLBACK_REQUEST);

const setCallbackURL = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SET_CALLBACK_URL);

const createRegistriesSkanReport = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_REGISTRIES_SKAN_REPORT);

const createPPVAmlParty = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_PPV_AML_PARTY);

const getPpvRequest = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_REQUEST);

const getPpvParty = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_PARTY);

const getPpvRuleSet = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_RULE_SET);

const getPpvRuleSetDescription = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_RULE_SET_DESCRIPTION);

const getPpvPartyRisk = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_PARTY_RISK);

const ppvRejectRuleResult = (state = null, action) =>
  singleSwitchReducer(state, action, constants.PPV_REJECT_RULE_RESULTS);

const ppvRiskRecalculate = (state = null, action) =>
  singleSwitchReducer(state, action, constants.PPV_RECALCULATE_RESULTS);

const updatePpvParty = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_PPV_PARTY);

const getPpvHistoryEvents = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_EVENTS);

const getPpvHistoryEvent = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_EVENT_DETAILS);

const deletePpvHistoryEvent = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_PPV_EVENT);

const getPpvReportDetails = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_REPORT_DETAILS);

const getPpvCustomerDueDiligenceExecutions = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS);

  const deletePpvCustomerDueDiligenceExecutions = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS);

const createPpvCustomerDueDiligenceExecution = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS);

const findPpvCustomerDueDiligenceExecution = (state = null, action) =>
  singleSwitchReducer(state, action, constants.PPV_FIND_DUE_DILIGENCE_EXECUTION);

const preparePpvReport = (state = null, action) =>
  singleSwitchReducer(state, action, constants.PPV_PREPARE_REPORT);

const getPpvReportsList = (state = null, action) =>
  singleSwitchReducer(state, action, constants.PPV_GET_PDF_LIST);

const screenPpvParty = (state = null, action) =>
  singleSwitchReducer(state, action, constants.PPV_PARTY_SCREEN);

const setPpvRuleResultStatus = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SET_PPV_RULE_RESULT_STATUS);

const getPpvBeneficiaries = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_BENEFICIARIES);

const deletePpvBeneficiary = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_PPV_BENEFICIARY);

const addPpvBeneficiary = (state = null, action) =>
  singleSwitchReducer(state, action, constants.ADD_PPV_BENEFICIARY);

const updatePpvBeneficiary = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_PPV_BENEFICIARY);

const getPpvBoardMembers = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_BOARDMEMBERS);

const addPpvBoardMember = (state = null, action) =>
  singleSwitchReducer(state, action, constants.ADD_PPV_BOARDMEMBER);

const deletePpvBoardMember = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_PPV_BOARDMEMBER);

const updatePpvBoardMember = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_PPV_BOARDMEMBER)

const getRuleDefaultLimits = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_RULE_LIMITS);

const getPpvEventComments = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_PPV_EVENT_COMMENTS);

const createPpvEventComment = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_PPV_EVENT_COMMENT);

const deletePpvComment = (state = null, action) =>
  singleSwitchReducer(state, action, constants.DELETE_PPV_COMMENT);

const updatePpvComment = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_PPV_COMMENT);

const createPpvHistoryEvent = (state = null, action) =>
  singleSwitchReducer(state, action, constants.CREATE_PPV_HISTORY_EVENT);

const updatePpvHistoryEvent = (state = null, action) =>
  singleSwitchReducer(state, action, constants.UPDATE_PPV_HISTORY_EVENT);

const addPpvMissingRuleData = (state = null, action) =>
  singleSwitchReducer(state, action, constants.ADD_PPV_MISSING_RULE_DATA);

const getReportList = (state = null, action) =>
  singleSwitchReducer(state, action, constants.GET_REPORT_LIST);

const setLanguage = (state = null, action) =>
  singleSwitchReducer(state, action, constants.SET_LANGUAGE);

const getSubscriptionStatus = (state = false, action) =>
  singleSwitchReducer(state, action, constants.GET_SUBSCRIPTION_STATUS);

const getApplicantFormCode = (state = false, action) =>
  singleSwitchReducer(state, action, constants.GET_APPLICANT_FORM_CODE);

const rootReducer = combineReducers({
  generalError,
  userLogin,
  loginRequired,
  userRegister,
  userEmailVerification,
  getParties,
  getPartySummaryStatus,
  getParty,
  preparePartyReport,
  getPartyRisk,
  getPartyAttachments,
  getPartyAttachmentsOneDrive,
  createParty,
  uploadPartyCsv,
  updateParty,
  setPartyRiskStatus,
  updatePartyStatus,
  deleteParty,
  createHistoryEvent,
  updateHistoryEvent,
  getHistoryEvents,
  getHistoryEvent,
  deleteHistoryEvent,
  getEventComments,
  createComment,
  updateComment,
  deleteComment,
  getUser,
  userChangePassword,
  userResendVerificationEmail,
  resetUserPassword,
  userSendResetPasswordEmail,
  addBeneficiary,
  deleteBeneficiary,
  getBeneficiaries,
  updateBeneficiary,
  getBoardMembers,
  addBoardMember,
  updateBoardMember,
  deleteBoardMember,
  getRegonAutofill,
  getBeneficiariesByNIPFromCRBR,
  getProductsData,
  getOrderCalculation,
  checkSubscription,
  getOrders,
  getOrder,
  getSubscriptions,
  getSubscriptionPlans,
  createTransaction,
  updateTransaction,
  updateTransactionStatus,
  getTransactions,
  getTransaction,
  getTransactionSummaryStatus,
  prepareTransactionReport,
  getTransactionAttachments,
  getTransactionAttachmentsOneDrive,
  deleteTransaction,
  setTransactionRiskStatus,
  getTransactionRisk,
  getAlerts,
  getAlert,
  updateAlertStatus,
  deleteAlert,
  createTask,
  getTasks,
  getTask,
  updateTask,
  getGoogleDriveInfo,
  googleLogin,
  googleLogout,
  addPartyAttachment,
  addPartyAttachmentOneDrive,
  addTransactionAttachment,
  addTransactionAttachmentOneDrive,
  deleteAttachment,
  deleteAttachmentOneDrive,
  updateAttachmentDescription,
  updateAttachmentDescriptionOneDrive,
  updateTaskStatus,
  createGoogleAuthToken,
  deleteTask,
  getRiskManagementStatus,
  updateRiskManagementStatus,
  getAvailableRules,
  getUserRules,
  getRule,
  getRuleLimits,
  addUserRule,
  removeUserRule,
  deleteUser,
  getUserToDelete,
  sendDeleteUserEmail,
  updateRuleLimits,
  addMissingRuleData,
  getSuggestedRiskStatusRecalculate,
  setRuleResultStatus,
  rejectRuleResult,
  updateHighRiskPkdList,
  createHighRiskCountryList,
  updateHighRiskCountryList,
  deleteHighRiskCountryList,
  createCorruptCountryList,
  updateCorruptCountryList,
  deleteCorruptCountryList,
  createTaxHavenCountryList,
  updateTaxHavenCountryList,
  deleteTaxHavenCountryList,
  createSanctionCountryList,
  updateSanctionCountryList,
  deleteSanctionCountryList,
  createTerrorismCountryList,
  updateTerrorismCountryList,
  deleteTerrorismCountryList,
  createEmployees,
  getEmployees,
  getEmployee,
  updateEmployees,
  deleteEmployee,
  addEmployeeTraining,
  getEmployeeTrainings,
  deleteEmployeeTraining,
  surveyCheck,
  surveySendEmail,
  sanctionsListsSearch,
  sanctionsListsSearchNoForm,
  sanctionsListsTransactionSearchNoForm,
  partySanctionsListsScreen,
  userInvoiceData,
  updatedUserInvoiceData,
  uploadTransactionSignedFormXml,
  getFinancialSecurityMeasuresExecutions,
  findFinancialSecurityMeasuresExecution,
  createFinancialSecurityMeasuresExecution,
  deleteFinancialSecurityMeasureExecution,
  uploadFinancialSecurityMeasureAttachment,
  uploadFinancialSecurityMeasureAttachmentOneDrive,
  getGoogleAccount,
  getGoogleAccountConnectionRedirectUrl,
  createGoogleAccountConnection,
  disconnectGoogleAccount,
  getMicrosoftAccount,
  getMicrosoftAccountConnectionRedirectUrl,
  createMicrosoftAccountConnection,
  disconnectMicrosoftAccount,
  logSwitch,
  createApiKeys,
  getApiKeys,
  getAllActiveApiKeys,
  disableApiKeys,
  setCallbackURL,
  testCallbackRequestResult,
  createApplicant,
  getApplicant,
  getApplicants,
  submitApplicant,
  updateIndividualCustomerProfileList,
  getIndividualCustomerProfileList,
  deleteIndividualCustomerProfile,
  createIndividualCustomerProfile,
  createRegistriesSkanReport,
  createPPVAmlParty,
  getPpvRequest,
  getPpvParty,
  getPpvRuleSet,
  getPpvRuleSetDescription,
  getPpvPartyRisk,
  ppvRejectRuleResult,
  ppvRiskRecalculate,
  updatePpvParty,
  getPpvHistoryEvents,
  getPpvReportDetails,
  getPpvCustomerDueDiligenceExecutions,
  createPpvCustomerDueDiligenceExecution,
  findPpvCustomerDueDiligenceExecution,
  deletePpvCustomerDueDiligenceExecutions,
  preparePpvReport,
  getPpvReportsList,
  screenPpvParty,
  setPpvRuleResultStatus,
  getPpvBeneficiaries,
  deletePpvBeneficiary,
  addPpvBeneficiary,
  updatePpvBeneficiary,
  getPpvBoardMembers,
  addPpvBoardMember,
  deletePpvBoardMember,
  updatePpvBoardMember,
  getRuleDefaultLimits,
  getPpvHistoryEvent,
  deletePpvHistoryEvent,
  getPpvEventComments,
  createPpvEventComment,
  deletePpvComment,
  updatePpvComment,
  createPpvHistoryEvent,
  updatePpvHistoryEvent,
  addPpvMissingRuleData,
  getReportList,
  setLanguage,
  getUserConsents,
  updateUserConsents,
  getSubscriptionStatus,
  getShopProducts,
  getShopCart,
  addShopItem,
  deleteShopItem,
  updateShopItem,
  addDiscountCode,
  deleteDiscountCode,
  createOrder,
  cancelOrder,
  getSession,
  transactionSanctionsListsScreen,
  getApplicantFormCode,
  verifyTunstileToken,
  getTeamMembers,
  inviteTeamMember,
  getTeamInvitationData,
  teamMemberRegister,
  getTeamInvitations,
  handleTeamInvitation,
  changeCurrentTeamContext,
  getUserTeams,
  deleteTeamInvitation,
  getTeamData,
  updateTeamData,
  createNewTeam,
  deleteTeam,
  deleteTeamMember,
  updateTeamMemberRole,
  leaveTeam,
});

function singleSwitchReducer(state, action, type){
  switch (action.type){
      case type:
        if (action.payload.status > 400) {
          return {...action.payload.data, statusCode: action.payload.status}
        }
        return action.payload.data;
      case constants.USER_LOGOUT:
        return null;
      default:
        break;
  }
  return state;
}

export default rootReducer;
